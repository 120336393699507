import React from "react";
import { IconProps } from "../icon";

export const ThumbsDownIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M17 2v11m5-3.2V5.2c0-1.12 0-1.68-.218-2.108a2 2 0 00-.874-.874C20.48 2 19.92 2 18.8 2H8.118c-1.461 0-2.192 0-2.782.267A3 3 0 004.06 3.361c-.354.542-.465 1.265-.687 2.71l-.523 3.4c-.293 1.904-.44 2.857-.157 3.598a3 3 0 001.32 1.539C4.704 15 5.667 15 7.595 15H8.4c.56 0 .84 0 1.054.109a1 1 0 01.437.437c.11.214.11.494.11 1.054v2.934A2.466 2.466 0 0012.465 22c.325 0 .62-.191.751-.488l3.36-7.562c.154-.344.23-.516.35-.642a1 1 0 01.384-.249c.164-.059.352-.059.729-.059h.76c1.12 0 1.68 0 2.108-.218a2 2 0 00.874-.874C22 11.48 22 10.92 22 9.8z"
    />
  </svg>
);
