import React from "react";
import { IconProps } from "../icon";

export const BellRinging_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M14 21h-4M2.294 5.82A4.007 4.007 0 014.326 2.3m17.377 3.52A4.007 4.007 0 0019.67 2.3M18 8A6 6 0 106 8c0 3.09-.78 5.206-1.65 6.605-.735 1.18-1.102 1.771-1.088 1.936.015.182.053.252.2.36.133.099.73.099 1.927.099h13.222c1.197 0 1.795 0 1.927-.098.147-.11.186-.179.201-.361.013-.165-.354-.755-1.088-1.936C18.78 13.206 18 11.09 18 8z"
    />
  </svg>
);
