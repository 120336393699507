import React from "react";
import { IconProps } from "../icon";

export const AlertTriangleIcon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M12 9v4m0 4h.01M10.615 3.892L2.39 18.098c-.456.788-.684 1.182-.65 1.506a1 1 0 00.406.705c.263.191.718.191 1.629.191h16.45c.91 0 1.365 0 1.628-.191a1 1 0 00.407-.705c.034-.324-.195-.718-.65-1.506L13.383 3.892c-.454-.785-.681-1.178-.978-1.31a1 1 0 00-.813 0c-.296.132-.523.525-.978 1.31z"
    />
  </svg>
);
