import React from "react";
import { IconProps } from "../icon";

export const BellOff_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M14 21h-4M8.633 3.034A6 6 0 0118 8c0 2.1.27 3.751.648 5.032M6.258 6.257A5.998 5.998 0 006 8c0 3.09-.78 5.206-1.65 6.605-.735 1.18-1.102 1.771-1.089 1.936.015.182.054.252.2.36.133.099.732.099 1.928.099H17m4 4L3 3"
    />
  </svg>
);
