import React from "react";
import { IconProps } from "../icon";

export const Announcement_02Icon = ({ size = "24px", color = "#000", ...props }: IconProps<string, string>) => (
  <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 14l1.575 6.299c.044.177.066.265.092.343A2 2 0 007.4 21.992c.08.007.172.007.355.007.228 0 .343 0 .44-.01a2 2 0 001.797-1.797c.009-.096.009-.21.009-.44V5.5m8.5 8a3.5 3.5 0 100-7m-8.25-1H6.5a4.5 4.5 0 000 9h3.75c1.766 0 3.927.947 5.594 1.856.973.53 1.46.795 1.778.756a.946.946 0 00.691-.411c.187-.26.187-.783.187-1.827V5.126c0-1.044 0-1.566-.187-1.827a.946.946 0 00-.691-.411c-.319-.039-.805.226-1.778.756-1.667.909-3.828 1.856-5.594 1.856z"
    />
  </svg>
);
